<template>
    <div>
        <div>
            <div class="d-flex align-center text-body-2">
                <v-icon size="16" left class="mr-1">mdi-timer-outline</v-icon> Recording limits <span class="ml-1 text-subtitle-2">{{ recordLengthConstraints }}</span>
                <v-spacer></v-spacer>
                <v-menu bottom :close-on-content-click="false">
                    <template v-slot:activator="{ on: menu, attrs: menuAttrs }">
                        <div v-bind="menuAttrs">
                            <tooltip :openDelay="1000">
                                <template #activator="{ on: tooltip, attrs }">
                                    <v-btn :class="[{'deepOrange lighten-5': hasVariablesAssigned}]" elevation="0" small icon :color="hasVariablesAssigned ? 'deepOrange accent-1' : undefined" v-bind="attrs" v-on="{...tooltip, ...menu}">
                                        <v-icon small>mdi-code-braces</v-icon>
                                    </v-btn>
                                </template>
                                <span class="text-caption white--text">
                                    Assign dynamic variables to the recording limits.
                                    <a 
                                        class="ml-2 grey--text text--lighten-1" 
                                        href="https://docs.voiceform.com/docs/voice-response-question#change-limits-dynamically" 
                                        target="_blank">
                                        Learn More
                                    </a>
                                </span>
                            </tooltip>
                        </div>
                    </template>
                    <v-card class="pa-4" min-width="400">
                        Assign Variables
                        <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            prepend-inner-icon="mdi-timer-sand"
                            class="mt-3 variable-input"
                            hide-details="auto"
                            v-model="minVariable"
                            placeholder="Minimum variable_name"
                            outlined
                            dense
                            :rules="[rules.variableRule]"
                            @change="updateQuestion"
                            color="deepOrange"
                        ></v-text-field>
                        <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            prepend-inner-icon="mdi-timer-sand-complete"
                            class="mt-3 variable-input"
                            hide-details="auto"
                            v-model="maxVariable"
                            placeholder="Maximum variable_name"
                            outlined
                            dense
                            :rules="[rules.variableRule]"
                            @change="updateQuestion"
                            color="deepOrange"
                        ></v-text-field>
                    </v-card>
                </v-menu>
            </div>
            <v-range-slider class="mx-0" v-model="intervalBound" :max="maxRecordingLimit>600? maxRecordingLimit : 600" min="0" step="5"
                 :rules="planUpperLimit" hide-details="true" @change="handleRangeChange">
            </v-range-slider>
            <div class="d-flex text-caption mx-2 mt-n2 grey--text text--darken-1">
                <span>0s</span>
                <v-spacer></v-spacer>
                <span class="text-right">{{maxRecordingLimit>600? `${maxRecordingLimit/60}min`:'10min'}}</span>
            </div>
        </div>
        <v-dialog v-model="showUpgradeDialog" width="500" class="audio-upgrade-dialog">
            <v-card class="px-7 pt-7 pb-5">
                <div class="text-h5 dialog-title">
                    Sorry, selecting a longer time limit is not available on your current plan
                </div>
                <img src="https://cdn.voiceform.com/media/time-upgrade.svg" class="my-4 mx-auto"
                    style="display: block; width: 350px" />
                <router-link :to="{ name: 'dashboard.upgrade' }">
                    <v-btn block color="primary" elevation="0">Upgrade your plan</v-btn>
                </router-link>
                <v-btn block text class="mt-3" @click="showUpgradeDialog = false">Maybe later</v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Tooltip from "@/components/Tooltip.vue";

export default {
    name: 'RecordingLimit',
    data() {
        return {
            showUpgradeDialog: false,
            planUpperLimit: [v => {
                return v[0] >= 0 && v[1] >= 30 && v[1] <= this.features.max_audio_response_time;
            }],
            rules: {
                variableRule: v => {
                    if (!v) return true;

                    // Updated regex to allow dot and bracket notation for nesting
                    const nestedVariablePattern = /^(?:[a-zA-Z_]\w*(?:\[\d+\]|(?:\.[a-zA-Z_]\w*)?)*)*$/;

                    return nestedVariablePattern.test(v) || 'Variable name must start with a letter or underscore and can only contain alphanumeric characters, underscores, dots, or bracket notation.';
                },
            },
        };
    },
    inject: ['updateQuestion'],
    props: {
        question: Object
    },
    components: {
        Tooltip
    },
    computed: {
        ...mapGetters({
            features: 'auth/features'
        }),
        maxRecordingLimit() {
            return this.features.max_audio_response_time;
        },
        
        recordLengthConstraints() {
            const lowerBound = this.$date.duration(this.question.properties.minRecordTime*1000).format('mm:ss') 
            const upperBound = this.$date.duration(this.question.properties.maxRecordTime*1000).format('mm:ss')
            return `${lowerBound}s - ${upperBound}s`;
        },
        intervalBound: {
            get() {
                return [this.question.properties.minRecordTime, this.question.properties.maxRecordTime];
            },
            set([minRecordTime, maxRecordTime]) {
                this.question.properties = {...this.question.properties, minRecordTime, maxRecordTime };
            }
        },
        hasVariablesAssigned() {
            return !!(this.minVariable || this.maxVariable);
        },
        minVariable: {
            get() {
                return this.question.properties.min_variable || '';
            },
            set(minVariable) {
                this.question.properties = {...this.question.properties, min_variable: minVariable };
            }
        },
        maxVariable: {
            get() {
                return this.question.properties.max_variable || '';
            },
            set(maxVariable) {
                this.question.properties = {...this.question.properties, max_variable: maxVariable };
            }
        },
    },
    methods: {
        handleRangeChange() {
            if (this.question.properties.maxRecordTime > this.features.max_audio_response_time) {
                this.setUpperMaxAudioLength();
                return this.showUpgradeDialog = true;
            }
            if (this.question.properties.maxRecordTime < 30) {
                this.question.properties.maxRecordTime = 30;
            }
            this.updateQuestion();
        },
        setUpperMaxAudioLength() {
            this.question.properties.maxRecordTime = this.features.max_audio_response_time;
            this.updateQuestion();
        }
    }
};
</script>

<style lang="scss" scoped>
.dialog-title {
    display: inline-block;
    max-width: 360px;
}
.variable-input{
    font-family: 'Roboto Mono', monospace;
}
</style>