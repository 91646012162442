<template>
    <v-tooltip top>
        <div class="text-caption">
            Upload is disabled in preview
        </div>
        <template v-slot:activator="{ on, attrs }">
            <div style="cursor:not-allowed !important;" v-bind="attrs" v-on="on">
                <Dashboard :key="`${question.id}-${plugins.length && plugins.join('-')}`"  :uppy="uppy" :props="{
                    proudlyDisplayPoweredByUppy: false,
                    width: '100%',
                    doneButtonHandler: null,
                    singleFileFullScreen: false,
                    showRemoveButtonAfterComplete: true,
                    disabled: true,
                    theme: styles.dark? 'dark' : 'light',
                    locale: dashboardLocale,
                    disableLocalFiles
                }" :plugins="plugins" />
            </div>
        </template>
    </v-tooltip>
</template>

<script>
import { Dashboard } from '@uppy/vue';
import Uppy from '@uppy/core';
import Webcam from '@uppy/webcam';
import ScreenCapture from '@uppy/screen-capture';
// Don't forget the CSS: core and UI components + plugins you are using
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import '@uppy/screen-capture/dist/style.min.css';

export default {
    name: "FileUpload",
    props: {
        value: {
            required: true,
        },
        question: {
            required: true,
        },
        survey: {
            required: true,
        },
        styles: {
            required: true,
        },
    },
    components: {
        Dashboard
    },
    computed: {
        disableLocalFiles(){
            return  this.question?.properties?.local_files === false
        },
        plugins() {
            let plugins = []
            if(this.question.properties.camera!==false){
                plugins.push('Webcam')
            }
            if(this.question.properties.screencast !== false){
                plugins.push('ScreenCapture')
            }
            return plugins
        },
        filesTypes(){
            return this.question?.properties?.file_types || null 
        },
        dashboardLocale(){
            if(this.filesTypes === 'videos'){
                let importFiles = 'Record or upload a video via:'

                if(this.disableLocalFiles){
                    importFiles = 'Select a video recording option:'

                    if(this.question?.properties.camera === false){
                        importFiles = 'Hit the button to screen record a video'
                    }

                    if(this.question?.properties.screencast === false){
                        importFiles = 'Hit the button to record a video'
                    }

                    if(this.question?.properties.camera === false && this.question?.properties.screencast === false){
                        importFiles = 'No options available to record a video'
                    }
                }

                return {
                    strings: {
                        importFiles,
                        dropPasteImportFiles: 'Record or upload a video here:',
                        myDevice: 'Upload video',
                    }
                }
            }
            return null
        },
        uppy() {
            const webcamConfig = this.filesTypes === 'videos' ? {
                mirror: true,
                facingMode: 'user',
                modes: ['video-audio'],
                locale: {
                    strings: {
                        pluginNameCamera: 'Record video',
                    }
                },
            }: {};

            return new Uppy({
                meta: {
                    formId: this.survey.id
                },
                restrictions: {
                    allowedFileTypes: this.question.properties.file_types_patterns || undefined,
                }

            })
                .use(Webcam, webcamConfig)
                .use(ScreenCapture)
        }
    }
};
</script>

<style lang="scss" scoped></style>
