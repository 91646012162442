<template>
    <div class="mt-4">
        <iframe
            v-if="isIframe"
            ref="videoIframe"
            :height="height"
            v-resize="onResize"
            frameborder="0" 
            :srcdoc="sourceDoc"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen
            style="width: 100%; max-width: 560px;"
        ></iframe>
        <iframe
            v-else
            ref="videoIframe"
            :height="height"
            v-resize="onResize"
            :src="videoSource"
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen
            style="width: 100%; max-width: 560px;"
        >
        </iframe>
    </div>
</template>
<script>
export default {
    name: 'VideoAttachment',
    data() {
        return {
            height: null,
        }
    },
    inject: ['question'],
    computed: {
        videoSource() {
            if(this.question && this.question.video_source) {
                // eslint-disable-next-line
                const regExp = /^https?\:\/\/.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
                const match = this.question.video_source.match(regExp);
                const uniqueId = (match && match[2].length === 11) ? match[2] : false;
                // eslint-disable-next-line
                if (!!uniqueId) {
                    return `https://www.youtube.com/embed/${uniqueId}?enablejsapi=1`;
                }
                return '';
            }
            return '';
        },
        isIframe() {
            if(this.question && this.question.video_source) {
                // eslint-disable-next-line
                const regex = /^<iframe.*>.*<\/iframe>/g;
                const match = this.question.video_source.match(regex);
                return !!match;
            }
            return false;
        },
        iframeSrc() {
            let source = this.question.video_source;
            if(this.isIframe){
                // eslint-disable-next-line
                source = this.question.video_source.replace(/width=\"\d*(px|%)?\"/, '');
                // eslint-disable-next-line
                source = source.replace(/height=\"\d*(px|%)?\"/, '');
                source = source.replace(/<iframe /, '<iframe width="100%" height="100%" ');
            }

            if (source && source.includes('{{')) {
                return `
                    <div style="
                        font: inherit; 
                        font-family: Poppins, sans-serif; 
                        color: #ffffff; 
                        background-color: #00000050; 
                        padding: 8px; 
                        border-radius: 4px; 
                        display: inline-flex; 
                        align-items: center;
                    ">
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 24 24" 
                            fill="#ffffff" 
                            width="24px" 
                            height="24px" 
                            style="margin-right: 8px;">
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v2h-2zm0 4h2v6h-2z"/>
                        </svg>
                        The video source contains dynamic variables. The video can be previewed only in the published survey mode.
                    </div>`;
            }
            return source;
        },
        sourceDoc() {
            return `<html style="margin: 0; padding: 0; height: 100%; overflow: hidden;">
                        <body style="margin: 0; padding: 0; height: 100%; overflow: hidden;">
                            ${this.iframeSrc}
                        </body>
                    </html>`;
        },
    },
    mounted() {
        this.onResize();
    },
    methods: {
        onResize() {
            const rectangle = this.$refs.videoIframe.getBoundingClientRect();
            if(rectangle.width) {
                this.height = (315/560)*rectangle.width;
            }
        }
    },
}
</script>

<style scoped>
    .iframe-class {
        width: 100%;
        max-width: 560px;
    }
</style>